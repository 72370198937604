import React from 'react';
import { FaSort, FaSortUp, FaSortDown } from 'react-icons/fa';

const RenderSortIcon = ({ sortField, field, sortOrder }) => {
    
    if (sortField !== field) return <FaSort />;
    if (sortOrder === 'asc') return <FaSortUp />;
    return <FaSortDown />;
};
export default RenderSortIcon;
