import React, { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Pagination from '../../../components/Pagination';
import Spinner from '../../../components/Spinner';
import SearchBar from '../../../components/SearchBar';
import ReminderFormModal from './ReminderFormModal';
import reminderService from '../Service/ReminderService';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import RenderSortIcon from '../../../components/RenderSortIcon.jsx';
import { mostrarMensajeAlerta } from '../../../util/alet.js';
import { AuthContext } from '../../../Context/AuthContext.js';

const ReminderList = () => {
    const { user } = useContext(AuthContext);
    const { page } = useParams();
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(parseInt(page) || 1);
    const [reminders, setReminders] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [searchField, setSearchField] = useState('name');
    const [searchQuery, setSearchQuery] = useState('');
    const [sortField, setSortField] = useState('name');
    const [sortOrder, setSortOrder] = useState('asc');
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [loading, setLoading] = useState(false);
    const [currentReminder, setCurrentReminder] = useState(null);

    const [modalOpen, setModalOpen] = useState(false);


    const filterList = [
        { key: 'name', value: 'Nombre' },
        { key: 'description', value: 'Descripción' },
        { key: 'dias', value: 'Días' }
    ];

    useEffect(() => {
        setCurrentPage(parseInt(page) || 1);
    }, [page]);

    useEffect(() => {
        fetchReminders(currentPage, searchField, searchQuery, sortField, sortOrder, itemsPerPage);
    }, [currentPage, searchField, searchQuery, sortField, sortOrder, itemsPerPage]);

    const handlePageChange = (page) => {
        navigate(`/reminders/${page}`);
    };

    const handleSearch = (field, query) => {
        setSearchField(field);
        setSearchQuery(query);
        setCurrentPage(1);
        navigate(`/reminder/1`);
    };

    const handleSort = (field) => {
        const order = sortField === field && sortOrder === 'asc' ? 'desc' : 'asc';
        setSortField(field);
        setSortOrder(order);
    };

    const handleItemsPerPageChange = (value) => {
        setItemsPerPage(value);
        setCurrentPage(1);
        navigate(`/reminder/1`);
    };

    const openModal = (reminder) => {
        setCurrentReminder(reminder);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
        setCurrentReminder(null);
    };


    const fetchReminders = async (page, searchField, searchQuery, sortField, sortOrder, limit) => {
        setLoading(true);
        try {
            const data = await reminderService.getReminders({ page, limit, field: searchField, search: searchQuery, sortField, sortOrder });
            setReminders(data.reminders);
            setTotalPages(data.totalPages);
        } catch (error) {
            console.error("Error obteniendo recordatorios", error);
        } finally {
            setLoading(false);
        }
    };


    const handleModalSubmit = async (currentReminder, data) => {
        if (currentReminder) {
            try {
                await reminderService.updateReminder(currentReminder._id, data);
                mostrarMensajeAlerta('success', "Éxito",
                    '¡Recordatorio actualizado exitosamente!', false);
                fetchReminders();
                closeModal();
            } catch (error) {
                mostrarMensajeAlerta('error', "Error",
                    error.response?.data?.message || '¡Error al actualizar el recordatorio!', false)
                console.error('Error creating reminder:', error);
            }
        } else {
            try {
                await reminderService.createReminder(data);
                mostrarMensajeAlerta('success', "Éxito",
                    '¡Recordatorio creado exitosamente!', false);
                fetchReminders();
                closeModal();
            } catch (error) {
                mostrarMensajeAlerta('error', "Error",
                    error.response?.data?.message || '¡Error al crear el recordatorio!', false)
                console.error('Error creating reminder:', error);
            }
        }

    }

    const deleteReminder = async (reminder) => {
        try {
            reminderService.deleteReminder(reminder._id);
            mostrarMensajeAlerta('success', "Éxito",
                '¡Recordatorio eliminaod exitosamente!', false)
            fetchReminders();
        } catch (error) {
            mostrarMensajeAlerta('error', "Error",
                error.response?.data?.message || '¡Error al elimiar el recordatorio!', false)
            console.error('Error deleate reminder:', error);
        }
    }

    const confirmDelete = (reminder) => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: '¡No podrás deshacer esto!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                deleteReminder(reminder);
            }
        });
    };


    return (
        <div className="container">
            <h1 className="mt-5">Recordatorios</h1>
            {user.permisos?.contactos?.editar && (
                <>
                    <button className="btn btn-primary" onClick={() => openModal(null)}>Nuevo Recordatorio</button>
                    <ReminderFormModal
                        open={modalOpen}
                        onClose={closeModal}
                        onSubmit={handleModalSubmit}
                        currentReminder={currentReminder}
                    />
                </>
            )}
            <SearchBar onSearch={handleSearch} filterList={filterList} />
            {loading ? (
                <Spinner />
            ) : (
                <>
                    <div className="table-responsive">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col" onClick={() => handleSort('name')}>Nombre  <RenderSortIcon sortField={sortField} field={"name"} sortOrder={sortOrder} /></th>
                                    <th scope="col" onClick={() => handleSort('frequency')}>Frecuencia  <RenderSortIcon sortField={sortField} field={"frequency"} sortOrder={sortOrder} /></th>
                                    <th scope="col" onClick={() => handleSort('description')}>Descripción  <RenderSortIcon sortField={sortField} field={"description"} sortOrder={sortOrder} /></th>
                                    <th scope="col" onClick={() => handleSort('message')}>Mensaje <RenderSortIcon sortField={sortField} field={"message"} sortOrder={sortField} /></th>
                                    {(user.permisos?.contactos?.editar || user.permisos?.contactos?.eliminar) && (<th scope="col" colSpan={user.permisos?.contactos?.editar && user.permisos?.contactos?.eliminar ? 2 : 1}>Acciones</th>)}
                                </tr>
                            </thead>
                            <tbody>
                                {reminders && reminders.map((reminder, index) => (
                                    <tr key={reminder._id}>
                                        <th scope="row">{index + 1 + (currentPage - 1) * itemsPerPage}</th>
                                        <td>{reminder.name}</td>
                                        <td>{reminder.frequency}</td>
                                        <td>{reminder.description}</td>
                                        <td dangerouslySetInnerHTML={{ __html: reminder.message }}></td>
                                        {user.permisos?.contactos?.editar && (
                                            <td>
                                                <button onClick={() => openModal(reminder)} className="btn btn-sm btn-success">
                                                    Editar
                                                </button>
                                            </td>
                                        )}
                                        {user.permisos?.contactos?.eliminar && (
                                            <td>
                                                <button onClick={() => confirmDelete(reminder)} className="btn btn-sm btn-danger">
                                                    Eliminar
                                                </button>
                                            </td>
                                        )}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                        itemsPerPage={itemsPerPage}
                        onItemsPerPageChange={handleItemsPerPageChange}
                    />
                </>
            )}
        </div>
    );
};

export default ReminderList;
