import React, { useState, useEffect } from 'react';
import DailyBookingsChart from './DailyBookingsChart';
import StatisticsTable from './StatisticsTable';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { MenuItem, Select, FormControl, Grid, TextField } from '@mui/material';
import instance from '../../../api/api';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import { mostrarMensajeAlerta } from '../../../util/alet';

const Dashboard = () => {
    const getFirstDayOfMonth = () => {
        const now = new Date();
        return new Date(now.getFullYear(), now.getMonth(), 1); // Primer día del mes actual
    };

    const [startDate, setStartDate] = useState(getFirstDayOfMonth());
    const [endDate, setEndDate] = useState(new Date());
    const [startDateStats, setStartDateStats] = useState(getFirstDayOfMonth());
    const [endDateStats, setEndDateStats] = useState(new Date());
    const [local, setLocal] = useState('all');
    const [chartData, setChartData] = useState([]);
    const [statsData, setStatsData] = useState({});
    const [locals, setLocals] = useState([]);

    useEffect(() => {
        instance.get(`/dashboard/daily-bookings`, {
            params: {
                startDate: startDate.toISOString(),
                endDate: endDate.toISOString(),
                localId: local
            }
        })
            .then(response => setChartData(response.data))
            .catch(err => {
                mostrarMensajeAlerta("error", "Error", err?.response?.data?.message, true);
                console.error(err)
            });
    }, [startDate, endDate, local]);

    useEffect(() => {
        instance.get('/local')
            .then(response => setLocals(response.data.locals))
            .catch(err => {
                mostrarMensajeAlerta("error", "Error", err?.response?.data?.message, true);
                console.error(err)
            });
    }, [startDate, local]);

    useEffect(() => {
        instance.get(`/dashboard/average-stats`, {
            params: {
                startDate: startDateStats.toISOString(),
                endDate: endDateStats.toISOString()
            }
        })
            .then(response => setStatsData(response.data))
            .catch(err => {
                mostrarMensajeAlerta("error", "Error", err?.response?.data?.message, true);
                console.error(err)
            });
    }, [startDateStats, endDateStats]);

    const handleStartDateChange = (date) => {
        if (date <= endDate) {
            setStartDate(date);
        }
    };

    const handleEndDateChange = (date) => {
        if (date >= startDate) {
            setEndDate(date);
        }
    };
    const handleStartDateStatsChange = (date) => {
        if (date <= endDate) {
            setStartDateStats(date);
        }
    };

    const handleEndDateStatsChange = (date) => {
        if (date >= startDate) {
            setEndDateStats(date);
        }
    };

    return (
        <div className='container'>
            <h1>Dashboard</h1>
            <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                    <Card >
                        <CardActionArea>
                            <CardContent>
                                <DailyBookingsChart data={chartData} />
                            </CardContent>
                        </CardActionArea>
                        <CardActions>
                            <FormControl style={{ minWidth: 120, padding: 1 }}>
                                <label htmlFor="local">Local</label>
                                <Select id="local" value={local} onChange={(e) => setLocal(e.target.value)}>
                                    <MenuItem value="all">Todos</MenuItem>
                                    {locals && locals.map(local => (
                                        <MenuItem value={local._id} key={local._id}>{local.local}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={6}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <label htmlFor="startDate">Fecha Desde</label>
                                        <DatePicker
                                            id="startDate"
                                            selected={startDate}
                                            onChange={handleStartDateChange}
                                            dateFormat="yyyy/MM/dd"
                                            customInput={<TextField fullWidth />}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <label htmlFor="endDate">Fecha Hasta</label>
                                        <DatePicker
                                            id="endDate"
                                            selected={endDate}
                                            onChange={handleEndDateChange}
                                            dateFormat="yyyy/MM/dd"
                                            customInput={<TextField fullWidth />}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Card >
                        <CardContent>
                            <StatisticsTable data={statsData} />
                        </CardContent>
                        <CardActions>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={6}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <label htmlFor="startDate">Fecha Desde</label>
                                        <DatePicker
                                            id="startDate"
                                            selected={startDateStats}
                                            onChange={handleStartDateStatsChange}
                                            dateFormat="yyyy/MM/dd"
                                            customInput={<TextField fullWidth />}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <label htmlFor="endDate">Fecha Hasta</label>
                                        <DatePicker
                                            id="endDate"
                                            selected={endDateStats}
                                            onChange={handleEndDateStatsChange}
                                            dateFormat="yyyy/MM/dd"
                                            customInput={<TextField fullWidth />}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
};

export default Dashboard;
