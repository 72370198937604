import React, { useState } from 'react';

const WorkoutTeensComponent = ({ onChange, isChecked}) => {
    const [showFullText, setShowFullText] = useState(false);

    const fullText = "Sabemos lo importante que es empezar a cuidarte. Cada cliente WorkoutTeens cuenta con kit de atención para que puedas disfrutar de esta experiencia (incluye bata, un chocolate y un regalo por tu visita). Parte de tu cuidado es eliminar ese molesto vello que empieza a ser visible en algunas zonas de tu cuerpo, por eso hemos diseñado un servicio pensado justamente en ti. Personal altamente capacitado te recibirá a ti y a tu acompañante, explicándoles el paso a paso del proceso de depilación y desarrollándolo con paciencia y cuidado para ofrecerte la mejor experiencia posible.";

    const toggleText = () => {
        setShowFullText(!showFullText);
    };


    return (
        <div className="card" style={{border: "0.25rem solid #e33084", borderRadius: "1rem"}}>
            <div className="card-body">
                <h5 className="card-title text-primary">¿Eres menor de 16 años?</h5>
                <p className="card-text text-dark font-weight-light">
                    Te ofrecemos una nueva experiencia.
                    <br />
                    {showFullText ? fullText : fullText.substring(0, 128) + '...'}
                    <span className="text-secondary" onClick={toggleText} style={{ cursor: 'pointer', }}>
                        {showFullText ? ' leer menos' : ' leer más'}
                    </span>
                </p>
                <div className="form-check form-check-inline-reverse">
                    <input className="form-check-input" type="checkbox" id="inlineCheckbox1" onChange={onChange} checked={isChecked} />
                    <label className="form-check-label text-primary font-weight-bold" htmlFor="inlineCheckbox1">
                        Agregar servicio WorkoutTeens
                    </label>
                </div>
            </div>
        </div>
    );
};

export default WorkoutTeensComponent;
