import React, { useCallback, useContext, useEffect, useState } from 'react';
import api from '../../../api/api';
import '../HomeBackOffice.css';
import { useNavigate } from 'react-router-dom';
import { mostrarMensajeAlerta } from '../../../util/alet';  // Corregido el nombre de importación
import { getLocalApi } from '../../../services/apiService';
import { AuthContext } from '../../../Context/AuthContext';


const LocalBookingAdmin = () => {
  const navigate = useNavigate();

  const [selectedLocalId, setSelectedLocalId] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [locales, setLocales] = useState([]);
  const [horario, setHorario] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState('');
  const [employees, setEmployees] = useState([]);
  const { user } = useContext(AuthContext);

  // Verifica si el usuario es administrador
  useEffect(() => {
    if (user.perfil !== "Administrador") {
      mostrarMensajeAlerta("error", "Autentificación", 'Lo sentimos, no es un usuario administrador.', true);
      navigate(`/`);
    }
  }, [navigate, user]);

  useEffect(() => {
    async function fetchLocales() {
      try {
        const response = await api.get("/local");
        if (response.status === 200) {
          setLocales(response.data.locals);
        }
      } catch (error) {
        console.error("Error al obtener la lista de locales:", error);
      }
    }

    fetchLocales();
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchHorasDisponibles = useCallback(async () => {
    if (selectedLocalId && selectedDate && selectedEmployee) {
      try {
        const response = await api.get(`/empleados/schedule/${selectedEmployee}/${selectedLocalId}/${selectedDate}`);
        if (response.status === 200) {
          setHorario(response.data.horario);
        }
      } catch (error) {
        console.error("Error al obtener las horas disponibles:", error);
      }
    } else {
      setHorario([])
    }
  });

  useEffect(() => {
    fetchHorasDisponibles();
  }, [selectedLocalId, selectedDate, selectedEmployee, fetchHorasDisponibles]);


  const handleLocalChange = async (event) => {
    setSelectedLocalId(event.target.value);
    if (event.target.value) {
      const response = await getLocalApi(event.target.value)
      setEmployees(response.localesConEmpleados);
    } else {
      setEmployees([])
    }
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const cambiarEstado = async (index) => {
    // Verifica si se ha seleccionado una hora válida
    if (selectedLocalId && selectedDate && horario[index]) {
      try {
        const body = {
          localId: selectedLocalId,
          date: selectedDate,
          hour: horario[index].hour,
          empleadoID: selectedEmployee,
        };

        // Realiza una solicitud para cambiar el estado de la hora
        const response = await api.post("/empleados/changeState/", body);

        if (response.status === 201 || response.status === 200) {
          fetchHorasDisponibles();
          mostrarMensajeAlerta("success", "Estado actualizado", '', false);
        } else {
          // Muestra una notificación de error con SweetAlert2
          mostrarMensajeAlerta("error", "Error al actualizar el estado", 'Error al actualizar el estado', false);
        }
      } catch (error) {
        // Muestra una notificación de error con SweetAlert2 en caso de error
        mostrarMensajeAlerta("error", "Error en la solicitud", 'Ocurrió un error al realizar la solicitud.', true);
        console.error('Error en cambiarEstado:', error);
      }
    }
  };

  return (
    <div className="container mt-4">
      <div className="row mt-3">
        <div className="col-lg-11 col-sm-12">
          <h1>Administrar Horario Empleado</h1>
        </div>
        <div className="col-lg-1 col-sm-12">
          <button className="btn btn-primary" onClick={() => navigate('/admin')}>
            Regresar
          </button>
        </div>
      </div>

      <form>
        <div className="form-group">
          <label>Seleccionar Local:</label>
          <select className="form-select" onChange={handleLocalChange}>
            <option value="">Seleccionar</option>
            {locales.map(local => (
              <option value={local._id} key={local._id}>{local.local}</option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label>Seleccionar Empleado:</label>
          <select className="form-select" onChange={(e) => setSelectedEmployee(e.target.value)}>
            <option value="">Seleccionar</option>
            {employees.map(employee => (
              <option value={employee._id} key={employee._id}>{employee.indentification}</option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label>Seleccionar Fecha:</label>
          <input
            type="date"
            className="form-control"
            onChange={handleDateChange}
          />
        </div>
        <div className="form-group">
          <label>Horario:</label>
          <div className="scrollable-table">
            <table className="table table-info hover" >
              <thead >
                <tr>
                  <th scope="col">Hora</th>
                  <th scope="col">Estado</th>
                </tr>
              </thead>
              <tbody>
                {horario.map((item, index) => (
                  <tr
                    key={index}
                    className={item.estado === "Ocupado" ? 'opcion-ocupada-hora' : ''}
                  >
                    <th>
                      {item.hour}
                    </th>
                    <td>
                      <button type="button" className={`btn ${item.available ? 'btn-success' : 'btn-danger'}`} onClick={() => cambiarEstado(index)}>
                        {item.available ? 'Disponible' : 'Ocupado'}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </form>
    </div>
  );
};

export default LocalBookingAdmin;
