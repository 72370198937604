import React, { useContext, useEffect, useState } from 'react';
import api from "../../../api/api";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import "./Styles.css"
import { AuthContext } from '../../../Context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { mostrarMensajeAlerta } from '../../../util/alet';

export default function ServiceData() {
  const navigator = useNavigate()
  const [services, setServices] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc'); // 'asc' para ascendente, 'desc' para descendente

  const { user } = useContext(AuthContext);

  useEffect(() => {
    getServices();
  }, []);

  const getServices = async () => {
    try {
      const response = await api.get("/service");
      if (response.data.services) {
        setServices(response.data.services);
      }
    } catch (error) {
      // Manejar errores si es necesario
      console.error('Error al obtener servicios:', error);
    }
  };

  const eliminateService = async (id) => {
    // Mostrar una alerta de confirmación
    const result = await Swal.fire({
      title: '¿Estás seguro?',
      text: 'Esta acción eliminará el servicio. Esta acción no se puede deshacer.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'Cancelar',
    });

    if (result.isConfirmed) {
      // El usuario confirmó la eliminación, llamar a la API de eliminación
      try {
        const response = await api.delete(`/service/${id}`);
        if (response.status === 200) {
          getServices();
          mostrarMensajeAlerta('success', "Éxito",
            '¡Servicio eliminado exitosamente!', false);
        }
      } catch (error) {
        // Manejar errores si es necesario
        console.error('Error al eliminar el servicio:', error);
        mostrarMensajeAlerta('error', "Error al eliminar el servicio",
          'Ha ocurrido un error al eliminar el servicio.', true)
      }
    }
  };

  const handleSort = (field) => {
    if (field === sortBy) {
      // Cambiar el orden de la clasificación si se hace clic en el mismo campo
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      // Establecer un nuevo campo para la clasificación
      setSortBy(field);
      setSortOrder('asc'); // Comenzar con el orden ascendente
    }
  };

  const filteredServices = services.filter((service) =>
    service && service.name && service.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Aplicar orden según el campo seleccionado
  if (sortBy) {
    filteredServices.sort((a, b) => {
      let compareA = a[sortBy];
      let compareB = b[sortBy];

      if (sortBy === 'duration') {
        // Si el campo es 'duration', asegúrate de que se compare como número
        compareA = parseFloat(compareA);
        compareB = parseFloat(compareB);
      }

      if (compareA < compareB) return sortOrder === 'asc' ? -1 : 1;
      if (compareA > compareB) return sortOrder === 'asc' ? 1 : -1;
      return 0;
    });
  }

  return (
      <div className="container-fluid mt-5">
        <div className="row justify-content-center">
          <div className="col-sm-8">
            {user.permisos?.servicios.ver ? (
              <div>
                <h2>Servicios Disponibles</h2>
                <input
                  type="text"
                  placeholder="Buscar servicio..."
                  className='form-control'
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />

                <button onClick={() => {
                  if (user.permisos?.servicios.editar) {
                    navigator('crearServicio')
                  } else {
                    mostrarMensajeAlerta('error', "Error",
                      '!No tienes los permisos necesarios!', true);
                  }
                }} className='main-button'>Crear Servicio</button>
                <div className="table-responsive mt-5">
                  <table className="table">
                    <thead>
                      <tr>
                        <th onClick={() => handleSort('name')}>
                          Nombre &nbsp; &nbsp;
                          {sortBy === 'name' && sortOrder === 'asc' && <FontAwesomeIcon icon={faSortUp} />}
                          {sortBy === 'name' && sortOrder === 'desc' && <FontAwesomeIcon icon={faSortDown} />}
                        </th>
                        <th onClick={() => handleSort('name')}>
                          Descripción &nbsp; &nbsp;
                          {sortBy === 'description' && sortOrder === 'asc' && <FontAwesomeIcon icon={faSortUp} />}
                          {sortBy === 'description' && sortOrder === 'desc' && <FontAwesomeIcon icon={faSortDown} />}
                        </th>
                        <th onClick={() => handleSort('duration')}>
                          Duración &nbsp; &nbsp;
                          {sortBy === 'duration' && sortOrder === 'asc' && <FontAwesomeIcon icon={faSortUp} />}
                          {sortBy === 'duration' && sortOrder === 'desc' && <FontAwesomeIcon icon={faSortDown} />}
                        </th>
                        <th onClick={() => handleSort('gender')}>
                          Género &nbsp; &nbsp;
                          {sortBy === 'gender' && sortOrder === 'asc' && <FontAwesomeIcon icon={faSortUp} />}
                          {sortBy === 'gender' && sortOrder === 'desc' && <FontAwesomeIcon icon={faSortDown} />}
                        </th>
                        <th onClick={() => handleSort('type')}>
                          Tipo &nbsp; &nbsp;
                          {sortBy === 'type' && sortOrder === 'asc' && <FontAwesomeIcon icon={faSortUp} />}
                          {sortBy === 'type' && sortOrder === 'desc' && <FontAwesomeIcon icon={faSortDown} />}
                        </th>
                        <th>Editar</th>
                        <th>Eliminar</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredServices.map((service) => (
                        <tr key={service._id}>
                          <td>{service.name}</td>
                          <td>{service.description}</td>
                          <td>{service.duration}</td>
                          <td>{service.gender}</td>
                          <td>{service.type}</td>
                          <td>
                            <button onClick={() => {
                              if (user.permisos?.servicios.editar) {
                                navigator(`/editarServicio/${service._id}`)
                              } else {
                                mostrarMensajeAlerta("error", "Error",
                                  '¡No tienes Permisos!', true);
                              }
                            }} className='btn btn-success'>Editar Servicio</button></td>
                          <td><button onClick={() => {
                            if (user.permisos?.servicios.eliminar) {
                              eliminateService(service._id)
                            } else {
                              mostrarMensajeAlerta("error", "Error",
                                '¡No tienes Permisos!', true);
                            }
                          }} className='btn btn-danger'>Eliminar</button></td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (<div className='col-sm-5 mt-5'><p>No tienes Permisos</p></div>)}

          </div>
        </div>
      </div>
  );
}
