import React, { useState } from 'react';

const SearchBar = ({ onSearch, filterList }) => {
    const [searchField, setSearchField] = useState(filterList[0].key || 'createdAt');
    const [searchQuery, setSearchQuery] = useState('');

    const handleSearch = (e) => {
        e.preventDefault();
        onSearch(searchField, searchQuery);
    };

    return (
        <form className="form-inline mb-3" onSubmit={handleSearch}>
            <div className="row">
                <div className="col-3">
                    <select
                        className="form-select"
                        value={searchField}
                        onChange={(e) => setSearchField(e.target.value)}
                    >
                        {filterList && filterList.map((item) => (
                            <option key={item.key} value={item.key}>{item.value}</option>
                        ))}
                    </select>
                </div>
                <div className="col-7">
                    <div className="form-group">
                        <input
                            type="text"
                            className="form-control"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            placeholder="Buscar"
                        />
                    </div>
                </div>
                <div className="col-1">
                    <button type="submit" className="btn btn-primary">Buscar</button>
                </div>
            </div>


        </form>
    );
};

export default SearchBar;
