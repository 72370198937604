import React from 'react';

const Pagination = ({ currentPage, totalPages, onPageChange, itemsPerPage, onItemsPerPageChange }) => {
    const renderPagination = () => {
        const pages = [];
        const totalShownPages = 5;
        const halfShownPages = Math.floor(totalShownPages / 2);

        const startPage = Math.max(1, currentPage - halfShownPages);
        const endPage = Math.min(totalPages, currentPage + halfShownPages);

        if (startPage > 1) {
            pages.push(
                <li key="first" className="page-item">
                    <button className="page-link" onClick={() => onPageChange(1)}>1</button>
                </li>
            );
            if (startPage > 2) {
                pages.push(
                    <li key="start-ellipsis" className="page-item disabled">
                        <span className="page-link">...</span>
                    </li>
                );
            }
        }

        for (let i = startPage; i <= endPage; i++) {
            pages.push(
                <li key={i} className={`page-item ${i === currentPage ? 'active' : ''}`}>
                    <button className="page-link" onClick={() => onPageChange(i)}>{i}</button>
                </li>
            );
        }

        if (endPage < totalPages) {
            if (endPage < totalPages - 1) {
                pages.push(
                    <li key="end-ellipsis" className="page-item disabled">
                        <span className="page-link">...</span>
                    </li>
                );
            }
            pages.push(
                <li key="last" className="page-item">
                    <button className="page-link" onClick={() => onPageChange(totalPages)}>{totalPages}</button>
                </li>
            );
        }

        return pages;
    };

    return (
        <nav className="mt-4">
            <ul className="pagination justify-content-center">
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button className="page-link" aria-label="Previous" onClick={() => onPageChange(currentPage - 1)} disabled={currentPage === 1}>
                        <span aria-hidden="true">&laquo;</span>
                        <span className="sr-only">Anterior</span>
                    </button>
                </li>
                {renderPagination()}
                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                    <button className="page-link" aria-label="Next" onClick={() => onPageChange(currentPage + 1)} disabled={currentPage >= totalPages}>
                        <span className="sr-only" >Siguiente</span>
                        <span aria-hidden="true">&raquo;</span>
                    </button>
                </li>
            </ul>
            <div className="row g-3 align-items-center justify-content-center">
                <div className="col-auto">
                    <label className="form-label" htmlFor="itemsPerPage">Número de elementos:</label>
                </div>
                <div className="col-auto">
                    <select
                        id="itemsPerPage"
                        className="form-select"
                        value={itemsPerPage}
                        onChange={(e) => onItemsPerPageChange(parseInt(e.target.value))}
                    >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                    </select>
                </div>
            </div>
        </nav>
    );
};

export default Pagination;
