import React, { useState } from 'react';
import instance from '../../api/api';
import { useNavigate } from 'react-router-dom';
import { mostrarMensajeAlerta } from '../../util/alet';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const navigate = useNavigate();
    const handleForgotPassword = async () => {
        try {
            const response = await instance.post('/user/forgot-password', { email });
            mostrarMensajeAlerta("success", "Éxito", response.data.message, true)
            navigate('/login');
        } catch (error) {
            mostrarMensajeAlerta("error", "ERROR", error.response.data.error || "ERROR", true)
        }
    };

    return (
        <div className="Auth-form-container">
            <div className="Auth-form-content">
                <h3 className="Auth-form-title">Recuperar Contraseña</h3>
                <div className="form-group">
                    <label htmlFor="correo">Correo Electrónico</label>
                    <input
                        type="email"
                        className="form-control"
                        placeholder="Correo Electrónico"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                <div className="d-grid gap-2 mt-3">
                    <button onClick={handleForgotPassword} className="main-button">Enviar</button>
                </div>
                <p className="forgot-password text-right mt-2">
                    ¿Ya tienes una  <a href="/login">cuenta?</a>
                </p>
            </div>
        </div>
    );
};

export default ForgotPassword;
