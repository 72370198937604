import React from 'react';
import "./Styles.css"
import Logo from "../Images/workoutLLogo.png"
import Footer from './Components/Footer';


function Home() {

    return (
        <>
            <div style={{ paddingTop: '60px' }}>
                <div className='container-fluid d-flex justify-content-center align-items-center' style={{ minHeight: '100vh' }}>
                    <div className='row justify-content-center align-items-center'>
                        <div className='col-sm-6'>
                            <h1>Reserva</h1>
                            <p>Realiza una reserva pulsado el botón que aparece a continuación</p>
                            <a href="/reserve"><button className='main-button' >Haz una reserva</button></a>
                        </div>
                        <div className='col-sm-6'>
                            <img className='img-fluid' src={Logo} alt="LogoWorkOut" />
                        </div>
                    </div>
                </div>
            </div>
            <Footer className="test" />
        </>


    );
}

export default Home;