import React from 'react';
import { IoLogoInstagram } from "react-icons/io";

const BronceadoPage = () => {
    return (
        <>
            <div className="col-12 text-center py-5 mb-5" style={{ background: 'linear-gradient(to bottom,  #1C8290, #ffffff)', marginTop: "2.5rem" }}>
                <h1 style={{ marginBottom: '10px', color: "#fff" }}>¡Logra un bronceado</h1>
                <h2 style={{ fontStyle: 'italic', display: 'block', fontWeight: "bold", color: "#e33084" }}>perfecto y duradero!</h2>
            </div>

            <div className="container">
                <div className="row mb-4">
                    <div className="col-md-3 text-white p-3 " style={{backgroundColor: " #1C8290", borderRadius: "10px"}}>
                        <h5 style={{ textOrientation: "sideways-right"}}>24-48 HORAS PREVIAS A TU SESIÓN DE BRONCEADO</h5>
                    </div>
                    <div className="col-md-9 p-3">
                        <h5 style={{color: "#1C8290"}}>EXFÓLIATE</h5>
                        <p>Prepara tu piel, eliminando células muertas y residuos de crema con un exfoliante de pH balanceado. Pregunta por nuestra exfoliación!!!</p>

                        <h5 style={{color: "#1C8290"}}>DEPÍLATE</h5>
                        <p>Depílate por lo menos 8 horas antes, recuerda que nuestra depilación con cera tibia es la opción más natural, delicada y duradera. Ten presente que una vez que te broncees no podrás hacerlo.</p>

                        <h5 style={{color: "#1C8290"}}>MANICURE & PEDICURE</h5>
                        <p>Ven a tu sesión con tu uñas ya pintadas.</p>
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-md-3 text-white p-3" style={{backgroundColor: "#e33084", borderRadius: "10px"}}>
                        <h5>DÍA DE LA SESIÓN DE BRONCEADO</h5>
                    </div>
                    <div className="col-md-9 p-3">
                        <h5 style={{color: "#e33084"}}>NO CREES BARRERAS PARA TU BRONCEADO</h5>
                        <p>Evita aplicar cualquier producto que actúe como barrera entre la piel y la solución de bronceado. La penetración profunda de la solución es la clave para un color hermoso y duradero. No uses cremas, desodorante o perfume.</p>

                        <h5 style={{color: "#e33084"}}>PROTEGE TUS NIVELES DE PH</h5>
                        <p>No uses geles de ducha, jabones o cremas hidratantes, estos productos pueden neutralizar la solución bronceadora.</p>

                        <h5 style={{color: "#e33084"}}>REMUEVE TODO EL MAQUILLAJE DE TU ROSTRO</h5>

                        <h5 style={{color: "#e33084"}}>LLEGA PREPARADA</h5>
                        <p>Usa ropa de color oscuro y floja, lleva sandalias y retira todas las joyas. La ropa apretada puede manchar la cintura, pecho, rodillas y codos.</p>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-md-3 text-white p-3" style={{backgroundColor: " #1C8290", borderRadius: "10px"}}>
                        <h5>DESPUÉS DE TU SESIÓN DE BRONCEADO</h5>
                    </div>
                    <div className="col-md-9 p-3">
                        <h5 style={{color: "#1C8290"}}>DÚCHATE</h5>
                        <p>Toma una ducha con agua tibia, no antes de 24 horas ya que la solución actúa durante ese tiempo, no uses jabón, ni geles de baño directamente sobre la piel.</p>

                        <h5 style={{color: "#1C8290"}}>NO TE EXFOLIES</h5>
                        <p>No uses geles de baño exfoliantes, ni esponjas de baño.</p>

                        <h5 style={{color: "#1C8290"}}>HIDRÁTATE</h5>
                        <p>Toma mucha agua y usa cremas humectantes sin alcohol, eso extenderá la vida de tu bronceado. Recuerda hacerlo dos veces al día posterior a tu primera ducha.</p>

                        <h5 style={{color: "#1C8290"}}>PROTÉGETE</h5>
                        <p>Si vas a tomar sol, utiliza bloqueador o bronceador en crema.</p>

                        <h5 style={{color: "#1C8290"}}>EVITA</h5>
                        <p>Bañarte en el mar, piscina y largas duchas. El mar, la piscina y el agua muy caliente acortan la duración de tu bronceado.</p>
                    </div>
                </div>

            </div>
            <div className="d-flex justify-content-center al m-0 py-3 fle" style={{ backgroundColor: "#e33084", color: "#fff" }}>
                <a href="http://www.workout.com.ec" style={{ color: "#fff", textDecoration: "none" }}>www.workout.com.ec   </a>{" "}/
                <a href="https://www.instagram.com/workout.ecu" style={{ color: "#fff", textDecoration: "none" }} target="_blank" rel="noopener noreferrer">
                    {" "}<IoLogoInstagram /> @workout.ecu
                </a>
            </div>
        </>

    );
};

export default BronceadoPage;
