import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const DailyBookingsChart = ({ data }) => {
    const chartData = {
        labels: data.map(entry => entry._id.date),
        datasets: [
            {
                label: '# de citas',
                data: data.map(entry => entry.count),
                fill: false,
                backgroundColor: '#e33084',
                borderColor: 'rgba(227, 48, 132, 0.2)',
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
            },
            title: {
                display: true,
                text: '# citas diarias',
            },
        },
    };

    return <Line data={chartData} options={options} />;
};

export default DailyBookingsChart;
