import instance from "../api/api"
import { mostrarMensajeAlerta } from "../util/alet";

export const getEmpleado = async (idEmpleado) => {
    try {
        const res = await instance.get("/empleados/" + idEmpleado);
        return res.data.empleado;
    } catch (error) {
        console.error(error);
    }
}

export const getEmpleados = async () => {
    try {
        const res = await instance.get("/empleados/");
        return res.data.empleados;
    } catch (error) {
        console.error(error);
    }
}


export const getLocales = async () => {
    try {
        const res = await instance.get("/local/");
        return res.data.locals;
    } catch (error) {
        console.error(error);
    }
}
export const createEmpleado = async (local_id, schedules, indentification) => {
    const data = { local_id, schedules, indentification };
    try {
        const res = await instance.post("/empleados", data);
        mostrarMensajeAlerta('success', "Éxito", '¡Empleado creado!', false);
        return res.data;
    } catch (error) {
        mostrarMensajeAlerta('error', "Error", '¡Error al crear empleado!', true);
        console.error(error);
    }
}

export const updateEmpleado = async (id, local_id, schedules, indentification) => {
    try {
        const res = await instance.put("/empleados/" + id, { local_id: local_id, schedules, indentification });
        mostrarMensajeAlerta('success', "Éxito", res.data.message, true);
        return res.data;
    } catch (error) {
        let message = "¡Error al actualizar empleado! ";
        console.error(error.response);
        if (error.response.status === 404) {
            if (error.response.data.bookings && error.response.data.bookings.length > 0) {
                message = "Empleado tiene citas agendadas:\n";
                // Mostrar hasta dos citas
                const maxToShow = 2;
                const bookingsToDisplay = error.response.data.bookings.slice(0, maxToShow);

                bookingsToDisplay.forEach(booking => {
                    message += `Local: ${booking.local.name}, Fecha: ${booking.date}, Hora: ${booking.hour}\n`;
                });

                // Verificar si hay más de dos citas
                if (error.response.data.bookings.length > maxToShow) {
                    message += " Existen más citas. ";
                }
                message += "Soluciona este problema primero."
            } else {
                message = error.response.data?.message;
            }
        }

        mostrarMensajeAlerta('error', message, '', true);
        console.error(error);
    }
}
export const getLocalApi = async (id) => {
    try {
        const res = await instance.get("/empleados/locales/" + id);
        return res.data;
    } catch (error) {
        console.error(error);
    }
}

export const createLocalApi = async (local) => {
    const data = { local, employees: [] };
    try {
        const res = await instance.post("/local", data);
        mostrarMensajeAlerta('success', "Éxito", 'Local creado!', false);
        return res.data;
    } catch (error) {
        mostrarMensajeAlerta('error', "Error", '¡Error al crear local!', true);
        console.error(error);
    }
}
export const updateLocalApi = async (id, local, employees) => {
    const data = { local, employees };
    try {
        const res = await instance.put("/local/" + id, data);
        mostrarMensajeAlerta('success', "Éxito", '¡Local actualizado!', false);
        return res.data;
    } catch (error) {
        mostrarMensajeAlerta('error', "Error", '¡Error al actualizar local!', true);
        console.error(error);
    }
}