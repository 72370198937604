import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import './Sidebar.css';
import { IconContext } from 'react-icons';
import logo from "../../Images/workOutLogo2.jpeg";
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';
import { BsFillFilePersonFill } from 'react-icons/bs';
import { AuthContext } from '../../Context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { RiContactsBook2Fill } from 'react-icons/ri';
import { AiOutlineSchedule } from "react-icons/ai";
import { MdDashboard } from "react-icons/md";

function Sidebar() {
    const navigator = useNavigate();
    const { user, logout } = useContext(AuthContext);

    const SidebarData = user.perfil === "Administrador"
        ? [
            {
                title: 'Dashboard',
                path: '/dashboard',
                icon: <MdDashboard />,
                cName: 'nav-text',
            },
            {
                title: 'Locales',
                path: '/local',
                icon: <AiIcons.AiFillHome />,
                cName: 'nav-text',
            },
            {
                title: 'Empleados',
                path: '/empleados',
                icon: <BsFillFilePersonFill />,
                cName: 'nav-text',
            },
            {
                title: 'Citas',
                path: '/booking',
                icon: <IoIcons.IoIosPaper />,
                cName: 'nav-text',
            },
            {
                title: 'Servicios',
                path: '/servicios',
                icon: <IoIcons.IoMdPeople />,
                cName: 'nav-text',
            },
            {
                title: 'Configuraciones',
                path: '/admin',
                icon: <FaIcons.FaCog />,
                cName: 'nav-text',
            },
        ]
        : [
            {
                title: 'Locales',
                path: '/local',
                icon: <AiIcons.AiFillHome />,
                cName: 'nav-text',
            },
            {
                title: 'Empleados',
                path: '/empleados',
                icon: <BsFillFilePersonFill />,
                cName: 'nav-text',
            },
            {
                title: 'Citas',
                path: '/booking',
                icon: <IoIcons.IoIosPaper />,
                cName: 'nav-text',
            },
            {
                title: 'Servicios',
                path: '/servicios',
                icon: <IoIcons.IoMdPeople />,
                cName: 'nav-text',
            }
        ];


    if (user.permisos && user.permisos.contactos?.ver) {
        SidebarData.push({
            title: 'Contactos',
            path: '/contacts/1',
            icon: <RiContactsBook2Fill />,
            cName: 'nav-text'
        });
    }

    if (user.permisos && user.permisos.recordatorios?.ver) {
        SidebarData.push({
            title: 'Recordatorios',
            path: '/reminder/1',
            icon: <AiOutlineSchedule />,
            cName: 'nav-text'
        });
    }

    const [sidebar, setSidebar] = useState(false);

    const showSidebar = () => setSidebar(!sidebar);

    return (
        <div style={{ zIndex: 100, position: "relative" }}>
            <IconContext.Provider value={{ color: '#fff' }} >
                <div className={sidebar ? 'navbar active' : 'navbar'} >
                    <Link to='#' className='menu-bars'>
                        <FaIcons.FaBars onClick={showSidebar} />
                    </Link>
                </div>
                <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
                    <ul className='nav-menu-items' onClick={showSidebar}>
                        <li className='navbar-toggle'>
                            <Link to='#' className='menu-bars'>
                                <AiIcons.AiOutlineClose />
                            </Link>
                        </li>
                        <img className="logo" src={logo} alt="Logo" />
                        <div className="user-info">
                            <p>Usuario: {user.name}</p>
                        </div>
                        {SidebarData.map((item, index) => (
                            <li key={index} className={item.cName}>
                                <Link to={item.path}>
                                    {item.icon}
                                    <span>{item.title}</span>
                                </Link>
                            </li>
                        ))}
                        <li onClick={() => {
                            logout();
                            navigator('/');
                        }} className='nav-text'>
                            <Link to="#">
                                <IoIcons.IoMdExit />
                                <span>Salir</span>
                            </Link>
                        </li>
                    </ul>
                </nav>
            </IconContext.Provider>
        </div>
    );
}

export default Sidebar;
