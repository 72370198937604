import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box } from '@mui/material';

const StatisticsTable = ({ data }) => {
    const rows = [
        { id: 1, name: 'Total Citas', value: data.totalBookings },
        { id: 2, name: 'Promedio Citas Mensuales', value: data.avgMonthlyBookings },
        { id: 3, name: 'Citas Workout teens', value: data.workoutTeensBookingsCount },
        { id: 4, name: 'Promedio Citas Canceladas', value: data.avgDailyCancelled },
        { id: 5, name: 'Promedio Citas Realizadas', value: data.avgDailyCompleted },
    ];

    // Colores
    const colors = [
        { background: '#E3F2FD', borderAndText: '#1976D2' }, // Azul pastel y azul más intenso
        { background: '#FFEBEE', borderAndText: '#D32F2F' }, // Rosa pastel y rojo más intenso
        { background: '#E8F5E9', borderAndText: '#388E3C' }, // Verde pastel y verde más intenso
        { background: '#FFF3E0', borderAndText: '#F57C00' }, // Naranja pastel y naranja más intenso
        { background: '#F3E5F5', borderAndText: '#7B1FA2' }, // Morado pastel y morado más intenso
    ];

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>#</TableCell>
                        <TableCell>Estadística</TableCell>
                        <TableCell align="right">Valor</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, index) => {
                        const { background, borderAndText } = colors[index % colors.length]; // Selección cíclica de colores
                        return (
                            <TableRow key={row.id}>
                                <TableCell>{row.id}</TableCell>
                                <TableCell>{row.name}</TableCell>
                                <TableCell align="right">
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: '100%',
                                            height: '100%',
                                            borderRadius: 2,
                                            borderColor: borderAndText, // Borde más intenso
                                            borderWidth: '2px',
                                            borderStyle: 'solid',
                                            backgroundColor: background, // Fondo pastel suave
                                            color: borderAndText, // Texto más intenso
                                            fontSize: '14px',
                                            fontWeight: 'bold',
                                            padding: 1
                                        }}
                                    >
                                        {row.value}
                                    </Box>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default StatisticsTable;
