import React from 'react';
import './Footer.css';

export default function Footer() {
    return (
        <div className='container-fluid footer2'>
            <div className='row justify-content-center'>
                <div className='col-sm'>
                    <footer className='footer' >
                        <p>&copy; WorkOut 2023</p>
                    </footer>
                </div>
            </div>
        </div>
    );
}
