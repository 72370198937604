import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from '../../../Context/AuthContext';
import Swal from 'sweetalert2';
import { createEmpleado, getEmpleado, getLocales, updateEmpleado } from '../../../services/apiService';

const EmployeForm = () => {
    const { user } = useContext(AuthContext);
    const navigator = useNavigate();
    const { id } = useParams();
    const [local, setLocal] = useState("");
    const [indentification, setIndentification] = useState('');
    const [schedules, setSchedules] = useState([
        { day: "lunes", opening: '09:00', closing: '20:00', isClosed: false },
        { day: "martes", opening: '09:00', closing: '20:00', isClosed: false },
        { day: "miércoles", opening: '09:00', closing: '20:00', isClosed: false },
        { day: "jueves", opening: '09:00', closing: '20:00', isClosed: false },
        { day: "viernes", opening: '09:00', closing: '20:00', isClosed: false },
        { day: "sábado", opening: '09:00', closing: '20:00', isClosed: false },
        { day: "domingo", opening: '09:00', closing: '20:00', isClosed: false },
    ]);

    const [locales, setLocales] = useState([]);

    useEffect(() => {
        async function fetchData() {
            try {
                if (id) {
                    const response = await getEmpleado(id);
                    setIndentification(response.indentification);
                    setLocal(response.local_id?._id);
                    setSchedules(response.schedules || []);
                }
            } catch (error) {
                console.error(error);
            }
        }
        fetchData();
    }, [id]);

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await getLocales();
                setLocales(response);
            } catch (error) {
                console.error(error);
            }
        }
        fetchData();
    }, []);

    const handleEditLocal = async (e) => {
        e.preventDefault();

        // Validación de campos obligatorios
        if (!indentification  || schedules.some(schedule => !schedule.opening || !schedule.closing)) {
            Swal.fire({
                icon: 'error',
                title: 'Campos incompletos',
                text: 'Todos los campos son obligatorios.',
            });
            return;
        }

        try {
            if (id) {
                await updateEmpleado(
                    id,
                    local,
                    schedules,
                    indentification
                );
            } else {
                await createEmpleado(
                    local,
                    schedules,
                    indentification
                );
            }
            navigator('/empleados');
        } catch (error) {
            console.error(error);
        }
    }

    const handleOpeningChange = (e, index) => {
        const newSchedules = [...schedules];
        newSchedules[index].opening = e.target.value;
        setSchedules(newSchedules);
    };

    const handleClosingChange = (e, index) => {
        const newSchedules = [...schedules];
        newSchedules[index].closing = e.target.value;
        setSchedules(newSchedules);
    };

    const handleToggleClosed = (index) => {
        const newSchedules = [...schedules];
        newSchedules[index].isClosed = !newSchedules[index].isClosed;
        setSchedules(newSchedules);
    };

    return (
            <div className='container-fluid mt-3'>
                <div className='row justify-content-center'>
                    <div className='col-sm-10'>
                        {user.permisos?.locales.ver ? (
                            <div>
                                <h2>{id ? "Editar Empleado" : "Crear Empleado"}</h2>
                                <form onSubmit={handleEditLocal}>
                                    <div className='form-group'>
                                        <label htmlFor='localName'>Empleado:</label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            id='localName'
                                            value={indentification}
                                            onChange={(e) => setIndentification(e.target.value)}
                                        />
                                    </div>
                                    <div className='form-group'>
                                        <label htmlFor='localName'>Local:</label>
                                        <br />&nbsp;
                                        <select className="form-select" onChange={(e) => setLocal(e.target.value)} value={local}>
                                            <option value="" selected>Seleccione un local</option>
                                            {locales && locales.length > 0 && locales.map((item, index) => (
                                                <option key={item._id} value={item._id}>{item.local}</option>
                                            ))}
                                        </select>
                                    </div>
                                    &nbsp;
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Día</th>
                                                <th>Inicio</th>
                                                <th>Abierto</th>
                                                <th>Disponible</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {["Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado", "Domingo"].map((day, index) => (
                                                <tr key={index}>
                                                    <td className='mt-3'>{day}</td>
                                                    <td>
                                                        <input
                                                            type='text'
                                                            className='form-control small-input'
                                                            value={schedules[index]?.opening || "09:00"}
                                                            onChange={(e) => handleOpeningChange(e, index)}
                                                            disabled={schedules[index].isClosed}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type='text'
                                                            className='form-control small-input'
                                                            value={schedules[index]?.closing || "20:00"}
                                                            onChange={(e) => handleClosingChange(e, index)}
                                                            disabled={schedules[index].isClosed}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="checkbox"
                                                            onChange={() => handleToggleClosed(index)}
                                                            checked={!schedules[index].isClosed}
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <button type='submit' className='main-button mt-3'>Guardar</button>
                                    &nbsp; &nbsp;
                                    <Link to="/empleados"><button type='button' className='main-close mt-3'>Regresar</button></Link>
                                </form>
                            </div>
                        ) : (
                            <div className='col-sm-5 mt-5'><p>No tienes Permisos</p></div>
                        )}
                    </div>
                </div>
            </div>
    );
}

export default EmployeForm;
