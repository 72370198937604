import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { mostrarMensajeAlerta } from '../../util/alet';
import instance from '../../api/api';

const ResetPassword = () => {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const { token } = useParams(); // Obtener el token de la URL
    const navigate = useNavigate();

    const handleResetPassword = async () => {
        try {
            // Validar que las contraseñas coincidan
            if (newPassword !== confirmPassword) {
                mostrarMensajeAlerta("error", "Datos incorrectos", 'Las contraseñas no coinciden', true)
                return;
            }
            // Enviar solicitud para restablecer la contraseña con el token
            const response = await instance.post(`/user/reset-password/${token}`, {
                newPassword,
                confirmPassword,
            });
            mostrarMensajeAlerta("success", "Éxito", response.data.message, true)
            // Redirigir al usuario a la página de inicio de sesión u otra página relevante
            navigate('/login');
        } catch (error) {
            mostrarMensajeAlerta("error", "ERROR", error.response.data.error || "ERROR", true)
        }
    };

    return (
        <div className="Auth-form-container">
            <div className="Auth-form-content">
                <h3 className="Auth-form-title">Restablecer Contraseña</h3>
                <div className="form-group">
                    <label htmlFor="password">Nueva Contraseña</label>
                    <input
                        type="password"
                        className="form-control"
                        placeholder="Nueva Contraseña"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="password">Confirmar Contraseña</label>
                    <input
                        type="password"
                        className="form-control"
                        placeholder="Confirmar Contraseña"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                </div>
                <div className="d-grid gap-2 mt-3">
                    <button onClick={handleResetPassword} className="main-button"> Restablecer Contraseña</button>
                </div>
                <p className="forgot-password text-right mt-2">
                    ¿Ya tienes una  <a href="/login">cuenta?</a>
                </p>
            </div>
        </div>
    );
};

export default ResetPassword;
