import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import ReCAPTCHA from 'react-google-recaptcha';
import { Container, Form, Button, Row, Col } from 'react-bootstrap';
import validator from 'validator'; // Importar la biblioteca validator
import instance from '../../api/api';
import { mostrarMensajeAlerta } from '../../util/alet';
import { useNavigate } from 'react-router-dom';

const Book = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [recaptchaValue, setRecaptchaValue] = useState(null);
    const navigate = useNavigate();

    const handleRecaptchaChange = (value) => {
        setRecaptchaValue(value);
    };

    const validateEmail = (email) => {
        return validator.isEmail(email) ? true : 'Formato de correo electrónico no válido';
    };

    const validatePhoneNumber = (phone) => {
        const ecuadorPhoneRegex = /^0[0-9]{9}$/;
        return ecuadorPhoneRegex.test(phone) ? true : 'Número de teléfono no válido. Debe ser un número de Ecuador válido.';
    };

    const onSubmit = async (data) => {
        if (recaptchaValue) {
            await instance.post( "/submit-complaint-and-suggestion", { 
                data,
                recaptchaValue,
            }
            );
            mostrarMensajeAlerta("success", "Enviado", '¡Tu reclamo/sugrencia fue enviado!', true);
            navigate('/');

            
        } else {
            mostrarMensajeAlerta("error", "Error", 'Por favor completa el reCAPTCHA', false);
        }
    };

    return (
        <Container className='mt-5'>
            <h1 className="my-4">Libro de Reclamos y Sugerencias</h1>
            <p>Los campos marcados con un asterisco (*) son obligatorios para dar una oportuna y efectiva atención. Gracias.</p>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm="2">Nombre <span style={{ color: 'red' }}>*</span></Form.Label>
                    <Col sm="10">
                        <Form.Control
                            type="text"
                            {...register('nombre', { required: 'Este campo es obligatorio' })}
                            isInvalid={!!errors.nombre}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.nombre?.message}
                        </Form.Control.Feedback>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm="2">Correo electrónico <span style={{ color: 'red' }}>*</span></Form.Label>
                    <Col sm="10">
                        <Form.Control
                            type="email"
                            {...register('email', {
                                required: 'Este campo es obligatorio',
                                validate: validateEmail
                            })}
                            isInvalid={!!errors.email}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.email?.message}
                        </Form.Control.Feedback>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm="2">Número de Teléfono <span style={{ color: 'red' }}>*</span></Form.Label>
                    <Col sm="10">
                        <Form.Control
                            type="text"
                            {...register('telefono', {
                                required: 'Este campo es obligatorio',
                                validate: validatePhoneNumber
                            })}
                            isInvalid={!!errors.telefono}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.telefono?.message}
                        </Form.Control.Feedback>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm="2">Motivo <span style={{ color: 'red' }}>*</span></Form.Label>
                    <Col sm="10">
                        <Form.Control
                            as="select"
                            {...register('motivo', { required: 'Este campo es obligatorio' })}
                            isInvalid={!!errors.motivo}
                        >
                            <option value="">Seleccionar Motivo</option>
                            <option value="reclamo">Reclamo</option>
                            <option value="sugerencia">Sugerencia</option>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            {errors.motivo?.message}
                        </Form.Control.Feedback>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm="2">Mensaje <span style={{ color: 'red' }}>*</span></Form.Label>
                    <Col sm="10">
                        <Form.Control 
                            as="textarea"  
                            placeholder="Escribe tu mensaje aquí..." 
                            rows={3} 
                            {...register('mensaje', { required: 'Este campo es obligatorio' })}
                            isInvalid={!!errors.mensaje} 
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.mensaje?.message}
                        </Form.Control.Feedback>
                    </Col>
                </Form.Group>
                <p>Los siguientes campos son opcionales pero serán útiles para mejorar la atención de sus requerimientos.</p>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm="2">Fecha de la cita</Form.Label>
                    <Col sm="10">
                        <Form.Control
                            type="date"
                            {...register('fechaCita')}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm="2">Hora de la cita</Form.Label>
                    <Col sm="10">
                        <Form.Control
                            type="time"
                            {...register('horaCita')}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm="2">Colaborador que le atendió</Form.Label>
                    <Col sm="10">
                        <Form.Control
                            type="text"
                            {...register('colaborador')}
                        />
                    </Col>
                </Form.Group>
                <Row className="mb-3">
                    <Col sm="10" offset-sm="2">
                        <ReCAPTCHA
                            sitekey="6LcpMiEqAAAAALCvPb0SGMDqtDTL76fm-iRcNqeS"
                            onChange={handleRecaptchaChange}
                        />
                    </Col>
                </Row>
                <Button type="submit">Enviar</Button>
            </Form>
        </Container>
    );
};

export default Book;
