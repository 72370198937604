import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import "./Styles.css";

const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}\s*$/i;
const telefonoEcuadorRegex = /^0[2-7]\d{7}$|^09\d{8}$/;

export default function FirstStep(props) {
    const { datosPersonales, setDatosPersonales, onNext } = props;

    const { control, handleSubmit, formState: { errors }, setValue, watch, trigger } = useForm({
        defaultValues: {
            nombre: datosPersonales.nombre,
            apellido: datosPersonales.apellido,
            correo: datosPersonales.correo,
            numero: datosPersonales.numero,
            genero: datosPersonales.genero,
            origen: datosPersonales.origen || [],
            otroOrigen: datosPersonales.otroOrigen || ''
        }
    });

    const watchNumero = watch("numero");
    const watchOrigen = watch("origen", []);
    const esOtroSeleccionado = watchOrigen.includes("Otro");

    // Buscar y cargar datos del localStorage
    const buscarDatos = (numero) => {
        if (numero) {
            const storedData = localStorage.getItem(numero);
            if (storedData) {
                const parsedData = JSON.parse(storedData);
                for (const [key, value] of Object.entries(parsedData)) {
                    setValue(key, value);
                }
            }
        }
    };

    const handleNumeroKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            buscarDatos(watchNumero);
        }
    };
    const handleNumeroBlur = (e) => {
        e.preventDefault();
        buscarDatos(watchNumero);
    };


    // Cargar el género desde datosPersonales si está disponible
    useEffect(() => {
        if (datosPersonales.genero) {
            setValue('genero', datosPersonales.genero);
        }
    }, [datosPersonales.genero, setValue]);

    const onSubmit = async (data) => {
        const valid = await trigger(['origen']);
        if (!valid) return;
        setDatosPersonales(data);

        // Guardar los datos en localStorage
        if (data.numero) {
            localStorage.setItem(data.numero, JSON.stringify(data));
        }

        onNext();
    };

    return (
        <div className='container'>
            <div className='row justify-content-center align-items-center'>
                <div className='col-sm-6'>
                    <h2>Paso 1: Datos personales</h2>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group">
                            <label htmlFor="numero" style={{ fontWeight: "bold" }}>Número de teléfono:</label>
                            <Controller
                                name="numero"
                                control={control}
                                rules={{ required: 'El número de teléfono es requerido.', pattern: { value: telefonoEcuadorRegex, message: 'Formato de número inválido.' } }}
                                render={({ field }) => (
                                    <input
                                        {...field}
                                        type="number"
                                        className={`form-control ${errors.numero ? 'is-invalid' : ''}`}
                                        placeholder="Número de telefono"
                                        onKeyDown={handleNumeroKeyDown}
                                        onBlur={handleNumeroBlur}
                                    />
                                )}

                            />
                            {errors.numero && (
                                <div className="invalid-feedback">{errors.numero.message}</div>
                            )}
                        </div>
                        <div className="form-group">
                            <label htmlFor="nombre" style={{ fontWeight: "bold" }}>Nombre:</label>
                            <Controller
                                name="nombre"
                                control={control}
                                rules={{ required: 'El nombre es requerido.', minLength: { value: 2, message: 'El nombre debe tener al menos 2 caracteres.' }, maxLength: { value: 20, message: 'El nombre debe tener como máximo 20 caracteres.' } }}
                                render={({ field }) => (
                                    <input
                                        {...field}
                                        className={`form-control ${errors.nombre ? 'is-invalid' : ''}`}
                                        placeholder="Nombre"
                                    />
                                )}
                            />
                            {errors.nombre && (
                                <div className="invalid-feedback">{errors.nombre.message}</div>
                            )}
                        </div>
                        <div className="form-group">
                            <label htmlFor="apellido" style={{ fontWeight: "bold" }}>Apellido:</label>
                            <Controller
                                name="apellido"
                                control={control}
                                rules={{ required: 'El apellido es requerido.', minLength: { value: 2, message: 'El apellido debe tener al menos 2 caracteres.' }, maxLength: { value: 20, message: 'El apellido debe tener como máximo 20 caracteres.' } }}
                                render={({ field }) => (
                                    <input
                                        {...field}
                                        className={`form-control ${errors.apellido ? 'is-invalid' : ''}`}
                                        placeholder="Apellido"
                                    />
                                )}
                            />
                            {errors.apellido && (
                                <div className="invalid-feedback">{errors.apellido.message}</div>
                            )}
                        </div>
                        <div className="form-group">
                            <label htmlFor="correo" style={{ fontWeight: "bold" }}>Correo:</label>
                            <Controller
                                name="correo"
                                control={control}
                                rules={{ required: 'El correo es requerido.', pattern: { value: emailRegex, message: 'Formato de correo inválido.' } }}
                                render={({ field }) => (
                                    <input
                                        {...field}
                                        className={`form-control ${errors.correo ? 'is-invalid' : ''}`}
                                        placeholder="Correo"
                                    />
                                )}
                            />
                            {errors.correo && (
                                <div className="invalid-feedback">{errors.correo.message}</div>
                            )}
                        </div>
                        <div className="form-group">
                            <label style={{ fontWeight: "bold" }}>Género:</label>
                            <Controller
                                name="genero"
                                control={control}
                                rules={{ required: 'El género es requerido.' }}
                                render={({ field }) => (
                                    <select
                                        {...field}
                                        className={`form-select ${errors.genero ? 'is-invalid' : ''}`}
                                    >
                                        <option value="">Seleccione</option>
                                        <option value="Masculino">El</option>
                                        <option value="Femenino">Ella</option>
                                    </select>
                                )}
                            />
                            {errors.genero && (
                                <div className="invalid-feedback">{errors.genero.message}</div>
                            )}
                        </div>
                        <div className="form-group">
                            <label htmlFor="origen" style={{ fontWeight: "bold" }}>¿En dónde nos conociste?:</label>
                            <Controller
                                name="origen"
                                control={control}
                                rules={{ required: 'Seleccione una opción.' }}
                                render={({ field }) => (
                                    <>
                                        <div>
                                            <label>
                                                <input
                                                    type="radio"
                                                    value="Redes sociales"
                                                    checked={field.value === "Redes sociales"}
                                                    onChange={field.onChange}
                                                />
                                                {" "}Redes sociales
                                            </label>
                                        </div>
                                        <div>
                                            <label>
                                                <input
                                                    type="radio"
                                                    value="Amigos o familiares"
                                                    checked={field.value === "Amigos o familiares"}
                                                    onChange={field.onChange}
                                                />
                                                {" "}Amigos o familiares
                                            </label>
                                        </div>
                                        <div>
                                            <label>
                                                <input
                                                    type="radio"
                                                    value="Cliente antiguo"
                                                    checked={field.value === "Cliente antiguo"}
                                                    onChange={field.onChange}
                                                />
                                                {" "}Cliente antiguo
                                            </label>
                                        </div>
                                        <div>
                                            <label>
                                                <input
                                                    type="radio"
                                                    value="Otro"
                                                    checked={field.value === "Otro"}
                                                    onChange={field.onChange}
                                                />
                                                {" "} Otro
                                            </label>
                                        </div>
                                    </>
                                )}
                            />
                            {errors.origen && (
                                <p style={{ color: "red", fontSize: "0.875rem" }}>{errors.origen.message}</p>
                            )}
                            {esOtroSeleccionado && (
                                <Controller
                                    name="otroOrigen"
                                    control={control}
                                    rules={{ required: 'Por favor, especifique otro origen en dónde nos conocistes.' }}
                                    render={({ field }) => (
                                        <input
                                            {...field}
                                            className={`form-control mt-2 ${errors.otroOrigen ? 'is-invalid' : ''}`}
                                            placeholder="Especifique otro"
                                        />
                                    )}
                                />
                            )}
                            {errors.otroOrigen && (
                                <div className="invalid-feedback">{errors.otroOrigen.message}</div>
                            )}
                        </div>

                        <button
                            type="submit"
                            className="main-button mt-2"
                        >
                            Siguiente
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}
