import axios from 'axios';

const instance = axios.create({
    baseURL: "https://booking.workout.com.ec/api/"
    //baseURL: "http://localhost:3004/api/"
});

let isRefreshing = false;
let failedQueue = [];


instance.interceptors.request.use(
    (config) => {
        if (localStorage.getItem('user-token')) {
            config.headers["Authorization"] = 'Bearer ' + localStorage.getItem('user-token');
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    response => response,
    async error => {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            if (isRefreshing) {
                return new Promise((resolve, reject) => {
                    failedQueue.push({ resolve, reject });
                }).then(token => {
                    originalRequest.headers['Authorization'] = 'Bearer ' + token;
                    return instance(originalRequest);
                }).catch(err => {
                    return Promise.reject(err);
                });
            }

            originalRequest._retry = true;
            isRefreshing = true;

            try {
                const response = await instance.post('/user/token', { token: localStorage.getItem('refreshToken') });
                localStorage.setItem('user-token', response.data.token);
                localStorage.setItem("refreshToken", response.data.refreshToken)
                originalRequest.headers['Authorization'] = 'Bearer ' + localStorage.getItem('user-token');
                return instance(originalRequest);
            } catch (err) {
                if (err.response.status === 403 || err.response.data?.tokenStatus === "Error Token") {
                    
                    try {
                        const tokenAux = localStorage.getItem('refreshToken');
                        localStorage.removeItem("user");
                        localStorage.removeItem("user-token");
                        localStorage.removeItem("refreshToken");
                        localStorage.removeItem("lastActiveTime");
                        localStorage.removeItem("lastExternalReferrerTime");
                        localStorage.removeItem("lastExternalReferrer");
                        window.location.href = '/login';
                        await instance.post('/user/logout', { refreshToken: tokenAux});
                    } catch (err) {
                        console.warn("TOKEN CADUCADO");
                    }
                
                }
                return Promise.reject(err);
            } finally {
                isRefreshing = false;
            }
        }

        return Promise.reject(error);
    }
);

export default instance;
