import instance from "../../../api/api";

const getReminders = async (remminder) => {
    const {page,limit,field,search,sortField,sortOrder} = remminder; 
    try {
        const response = await instance.get(`/reminders`, {
            params: {
                page,
                limit,
                field,
                search,
                sortField,
                sortOrder
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

const createReminder = async (reminderData) => {
    try {
        const response = await instance.post(`/reminders`, reminderData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const updateReminder = async (id, reminderData) => {
    try {
        const response = await instance.put(`/reminders/${id}`, reminderData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const deleteReminder = async (id) => {
    try {
        const response = await instance.delete(`/reminders/${id}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const reminderService = {
    getReminders,
    createReminder,
    updateReminder,
    deleteReminder
};

export default reminderService;