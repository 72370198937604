import React, { useState, useEffect } from 'react';
import api from "../../api/api";

function ThirdStep(props) {
    const { onNext, onPrev, info, setInfo, duracion, setHora, setHoraFinal, setlocalId, setEmployee, setHoraNew, setNewFecha } = props;
    const [localSeleccionado, setLocalSeleccionado] = useState(info.local_id);
    const [fechaSeleccionada, setFechaSeleccionada] = useState(info.fecha);
    const [horaSeleccionada, setHoraSeleccionada] = useState('');
    const [localesDisponibles, setLocalesDisponibles] = useState([]);
    const [horasDisponibles, setHorasDisponibles] = useState([]);
    const [formErrors, setFormErrors] = useState({});
    const [localId2, setlocalId2] = useState(info.local_id);

    useEffect(() => {
        async function fetchLocales() {
            try {
                const response = await api.get("/local");
                if (response.status === 200) {
                    setHoraSeleccionada('')
                    setLocalesDisponibles(response.data.locals);
                    setLocalSeleccionado(response.data.locals[0])
                    setlocalId2(response.data.locals[0]._id)
                    setlocalId(response.data.locals[0]._id)
                    setNewFecha(fechaSeleccionada)
                }
            } catch (error) {
                console.error("Error al obtener la lista de locales:", error);
            }
        }
        fetchLocales();
    }, [fechaSeleccionada, setNewFecha, setlocalId]);


    useEffect(() => {
        // Llamar a la API para obtener las horas disponibles cuando se selecciona un local y una fecha
        async function fetchHorasDisponibles() {
            setHorasDisponibles([])
            if (localSeleccionado && fechaSeleccionada) {
                const body = {
                    fechaSeleccionada: fechaSeleccionada,
                    id: localId2,
                    duration: duracion
                };

                try {
                    const response = await api.post("/schedule", body);
                    if (response.status === 200) {
                        setHorasDisponibles(response.data.horarioDisponible);
                    }
                } catch (error) {
                    console.error("Error al obtener las horas disponibles:", error);
                }
            }
        }

        fetchHorasDisponibles();
    }, [duracion, fechaSeleccionada, localId2, localSeleccionado]);

    // Validar si el formulario está completo
    useEffect(() => {
        const errors = {};

        if (!localSeleccionado) {
            errors.local = "Selecciona un local";
        }

        if (!fechaSeleccionada) {
            errors.fecha = "Selecciona una fecha";
        }

        if (!horaSeleccionada) {
            errors.hora = "Selecciona una hora";
        }

        setFormErrors(errors);
    }, [localSeleccionado, fechaSeleccionada, horaSeleccionada]);



    function getFechaActual() {
        const fechaActual = new Date();
        const anio = fechaActual.getFullYear();
        const mes = (fechaActual.getMonth() + 1).toString().padStart(2, '0');
        const dia = fechaActual.getDate().toString().padStart(2, '0');
        return `${anio}-${mes}-${dia}`;
    }

    function getFechaMinima() {
        return getFechaActual();
    }
    function isFormComplete() {
        if (!localSeleccionado || !fechaSeleccionada || !horaSeleccionada) {
            return false;
        }
        return true;
    }


    const horarioSeleccionado = (index) => {
        setEmployee(horasDisponibles[index].employees[0])
        setHora(horasDisponibles[index].hour)
        setHoraNew(horasDisponibles[index].hour)
        setHoraFinal(horasDisponibles[index].hour_end)
        setHoraSeleccionada(horasDisponibles[index].hour)
    };


    return (

        <div className='row justify-content-center'>
            <div className='col-sm-6'>
                <h2>Selección de Local, Fecha y Hora</h2>
                <div className='mt-2 form-group'>
                    <select
                        className="form-select"
                        value={localSeleccionado}
                        onChange={(e) => {
                            const selectedValue = e.target.value;
                            const selectedLocal = localesDisponibles.find((local) => local.local === selectedValue);

                            setlocalId2(selectedLocal._id)
                            setlocalId(selectedLocal._id)

                            if (selectedLocal) {
                                setLocalSeleccionado(selectedValue);
                                setInfo({
                                    ...info,
                                    local: selectedLocal.local,
                                    local_id: selectedLocal._id,
                                });
                            }
                        }}
                    >
                        {localesDisponibles.map((local) => (
                            <option key={local._id} value={local.local}>
                                {local.local}
                            </option>
                        ))}
                    </select>
                    {formErrors.local && <p className="error-message">{formErrors.local}</p>}
                </div>
                <div className='mt-2 form-group'>
                    <input
                        type="date"
                        value={fechaSeleccionada}
                        min={getFechaMinima()}
                        onChange={(e) => {
                            const nuevaFecha = e.target.value;
                            if (nuevaFecha >= getFechaActual()) {
                                setFechaSeleccionada(nuevaFecha);
                                setHoraSeleccionada('');
                                setHora('');
                                setNewFecha(nuevaFecha)
                                setInfo({ ...info, fecha: nuevaFecha });
                            }
                            setInfo({ ...info, fecha: e.target.value });
                        }}
                        className="form-control"
                    />
                    {formErrors.fecha && <p className="error-message">{formErrors.fecha}</p>}
                </div>
                <div className='mt-2 form-group'>
                    <div className="hora-dropdowns">
                        <select
                            size="5"  
                            onChange={e => { horarioSeleccionado(e.target.value); }}
                            className="form-select"                        >
                            {horasDisponibles && horasDisponibles.map((horario, index) => (
                                <option className='option-divider' key={index} value={index}>
                                    {horario.hour}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>

                <button className="main-button mt-2" onClick={onPrev}>Anterior</button> &nbsp; &nbsp;
                <button
                    className="main-close mt-2"

                    onClick={() => {
                        onNext();
                    }}
                    disabled={!isFormComplete()}
                >
                    Continuar
                </button>
            </div>
        </div>

    );
}

export default ThirdStep;
