import React from 'react'

export default function ResumenModify(props) {
    const { info, servicios, onNext, duracion, isWorkoutTeensChecked, timeWorkoutTeens } = props

    return (

        <div>
            <h2>Resumen</h2>

            <div className='row justify-content-center'>
                <div className='col-sm-5'>
                    <button onClick={onNext} className="main-button mt-2">Editar Reserva</button>
                </div>
            </div>

            <div className='row justify-content-center mt-3'>

                <div className='col-sm-5'>

                    <h5>Servicios</h5>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Servicio</th>
                                <th>Duración</th>
                            </tr>
                        </thead>
                        <tbody>
                            {servicios.map((servicio, index) => (
                                <>
                                    <tr key={index}>
                                        <td>{servicio.name}</td>
                                        <td>{servicio.duration} minutos</td>
                                    </tr>
                                    {isWorkoutTeensChecked &&
                                        <tr key={index}>
                                            <td>Workout Teens</td>
                                            <td>{timeWorkoutTeens} minutos</td>
                                        </tr>}
                                </>
                            ))}
                        </tbody>
                    </table>

                    <p>Duración Total: {duracion} minutos</p>



                </div>

                <div className='col-sm-5'>

                    <h5>Local, hora y fecha</h5>

                    <form>
                        <div className="form-group">
                            <label htmlFor="nombre">Local:</label>
                            <input
                                type="text"
                                id="local"
                                name="local"
                                className="form-control"
                                placeholder="local"
                                value={info.local}
                                readOnly // Hace que el campo sea de solo lectura
                                style={{ backgroundColor: '#f2f2f2' }} // Cambia el fondo a gris claro
                            />

                        </div>
                        <div className="form-group">
                            <label htmlFor="correo">Fecha:</label>
                            <input
                                type="text"
                                id="fecha"
                                name="fecha"
                                className="form-control"
                                placeholder="fecha"
                                value={info.fecha}
                                readOnly // Hace que el campo sea de solo lectura
                                style={{ backgroundColor: '#f2f2f2' }} // Cambia el fondo a gris claro
                            />

                        </div>
                        <div className="form-group">
                            <label htmlFor="numero">Hora</label>
                            <input
                                type="text"
                                id="hora"
                                name="hora"
                                className="form-control"
                                placeholder="hora"
                                value={info.hora}
                                readOnly // Hace que el campo sea de solo lectura
                                style={{ backgroundColor: '#f2f2f2' }} // Cambia el fondo a gris claro
                            />

                        </div>

                    </form>


                </div>



            </div>



        </div >
    )
}
