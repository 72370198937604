import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import Modal from 'react-bootstrap/Modal';
import api from '../../../api/api';
import '../HomeBackOffice.css';
import { mostrarMensajeAlerta } from '../../../util/alet';

const permissionSections = [
    {
        title: 'Locales',
        category: 'locales',
    },
    {
        title: 'Empleados',
        category: 'empleados',
    },
    {
        title: 'Citas',
        category: 'citas',
    },
    {
        title: 'Servicios',
        category: 'servicios',
    },
]

export default function Perfil() {
    const [showModal, setShowModal] = useState(false);
    const [nombrePerfil, setNombrePerfil] = useState('');
    const [errors, setErrors] = useState({});
    const [perfiles, setPerfiles] = useState([]);
    const [perfil, setPerfil] = useState(null);

    // Obtener la lista de perfiles desde la API
    async function fetchPerfiles() {
        try {
            const response = await api.get('/profile');
            if (response.status === 200 || response.status === 204) {
                setPerfiles(response.data.perfiles);
            }
        } catch (error) {
            console.error('Error al obtener la lista de perfiles:', error);
        }
    }

    useEffect(() => {
        fetchPerfiles();
    }, []);

    const validarFormulario = () => {
        const errores = {};

        if (!nombrePerfil) {
            errores.nombrePerfil = 'El nombre del perfil es obligatorio';
        }

        return errores;
    };

    const crearNuevoPerfil = () => {
        // Validar el formulario
        const errores = validarFormulario();

        if (Object.keys(errores).length === 0) {
            // Los datos son válidos, crear el perfil
            const nuevoPerfil = {
                name: nombrePerfil
            };

            // Enviar los datos del nuevo perfil al servidor
            api
                .post('/profile/create', nuevoPerfil)
                .then((response) => {
                    if (response.status === 200 || response.status === 204 || response.status === 201) {
                        // Actualizar la lista de perfiles con la respuesta del servidor

                        setPerfil(null)
                        // Limpiar los campos del formulario
                        setNombrePerfil('');

                        // Cerrar el modal
                        cerrarModal();

                        fetchPerfiles();
                        mostrarMensajeAlerta("success", "Perfil Creado",
                            'El perfil ha sido creado exitosamente', false);
                    } else {
                        console.error('Error al crear el perfil:', response.data.message);
                        mostrarMensajeAlerta("error", "Error al crear perfil",
                            'Hubo un error al crear el perfil', false);
                    }
                })
                .catch((error) => {
                    console.error('Error al crear el perfil:', error.response.data.message);
                    mostrarMensajeAlerta("error", "Error al crear perfil",
                        error.response.data.message, true);
                    console.error('Error al crear el perfil:', error);
                });
        } else {
            // Mostrar mensajes de error
            setErrors(errores);
        }
    };

    const abrirModal = () => {
        setShowModal(true);
    };

    const cerrarModal = () => {
        setShowModal(false);
    };

    const abrirPanel = async (id) => {
        try {
            const response = await api.get('/profile/' + id);
            if (response.status === 200) {
                setPerfil(response.data.perfil);
            }
        } catch (error) {
            console.error('Error al obtener la lista de perfiles:', error);
        }
    }


    const handleCheckboxChange = (permisoCategoria, valor) => {
        setPerfil((prevUsuario) => {
            const [categoria, permiso] = permisoCategoria.split('.');
            return {
                ...prevUsuario,
                permisos: {
                    ...prevUsuario.permisos,
                    [categoria]: {
                        ...prevUsuario.permisos[categoria],
                        [permiso]: valor,
                    },
                },
            };
        });
    };
    const handleGuardarPermisos = () => {
        api.put("/profile/update/" + perfil._id, { name: perfil.name, permisos: perfil.permisos }).then((response) => {
            if (response.status === 200) {
                mostrarMensajeAlerta("success", "Permisos Actualizados",
                    "", false);
                fetchPerfiles();
                setPerfil(null);
            }
        })
    };

    const handleEliminarPerfil = async () => {
        if (perfil) {
            const confirmacion = await Swal.fire({
                title: '¿Estás seguro?',
                text: 'Esta acción eliminará el perfil permanentemente.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sí, eliminar',
                cancelButtonText: 'Cancelar',
            });

            if (confirmacion.isConfirmed) {
                try {
                    const response = await api.delete('/profile/delete/' + perfil._id);
                    if (response.status === 200 || response.status === 204) {
                        mostrarMensajeAlerta("success", "Perfil Eliminado",
                            "El perfil ha sido eliminado exitosamente", false);
                        // Limpiar el estado del perfil seleccionado
                        setPerfil(null);
                        // Actualizar la lista de perfiles
                        fetchPerfiles();
                    }
                } catch (error) {
                    console.error('Error al eliminar el perfil:', error);
                    mostrarMensajeAlerta("error", "Error al eliminar perfi",
                        "Hubo un error al eliminar el perfil", false);
                }
            }
        }
    };

    return (
        <div className="container">
            <div className="row mt-3">
                <div className="col-11">
                    <h1>Perfil de Usuarios</h1>
                </div>
                <div className="col-1 text-right">
                    <a href="/admin" className="btn btn-primary">Regresar</a>
                </div>
                <div className={perfil ? " col-md-6" : " col-md-12"}>
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th>Perfiles</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><button className="main-button" onClick={abrirModal}>
                                    Crear Nuevo Perfil
                                </button></td>
                            </tr>
                            {perfiles.map((profile) => (
                                <tr key={profile._id}>
                                    <td onClick={() => abrirPanel(profile._id)}
                                        className={perfil && perfil._id === profile._id ? 'table-active' : ''}
                                    >
                                        {profile.name}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                {perfil && (
                    <div className='col-md-6'>
                        <div className="card">
                            <div className="card-header d-flex justify-content-between align-items-center">
                                <h2 className="card-title">{perfil.name}</h2>
                                <button
                                    className="btn btn-secondary"
                                    onClick={() => setPerfil(null)}
                                >
                                    Cerrar Panel
                                </button>
                            </div>
                            <div className="card-body">
                                <h5 className="mb-3">Editar Permisos</h5>
                                <div className="card mb-3">
                                    {permissionSections.map((section, index) => (
                                        <div className="card-body" key={index}>
                                            <h6>{section.title}</h6>
                                            {Object.keys(perfil.permisos[section.category]).map((key) => (
                                                <div className="form-check form-check-inline" key={key}>
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={perfil.permisos[section.category][key]}
                                                        onChange={(e) => handleCheckboxChange(`${section.category}.${key}`, e.target.checked)}
                                                    />
                                                    <label className="form-check-label">{key.charAt(0).toUpperCase() + key.slice(1)}</label>
                                                </div>
                                            ))}
                                        </div>
                                    ))}
                                </div>
                                <div className="d-flex justify-content-end">
                                    <button className="btn btn-success mr-2" onClick={handleGuardarPermisos}>
                                        Guardar
                                    </button>
                                </div>
                            </div>
                            <div className="card-footer text-center">
                                <button
                                    className="btn btn-danger"
                                    onClick={handleEliminarPerfil}
                                >
                                    Eliminar Perfil
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <Modal show={showModal} onHide={cerrarModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Crear Nuevo Perfil</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="form-group">
                            <label htmlFor="nombrePerfil">Nombre del Perfil</label>
                            <input
                                type="text"
                                className={`form-control ${errors.nombrePerfil ? 'is-invalid' : ''}`}
                                id="nombrePerfil"
                                placeholder="Nombre del Perfil"
                                value={nombrePerfil}
                                onChange={(e) => setNombrePerfil(e.target.value)}
                            />
                            {errors.nombrePerfil && <div className="invalid-feedback">{errors.nombrePerfil}</div>}
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-light" onClick={cerrarModal}>
                        Cancelar
                    </button>
                    <button className="btn btn-primary" onClick={crearNuevoPerfil}>
                        Crear Perfil
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}