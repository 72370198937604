import Swal from "sweetalert2";

export const mostrarMensajeAlerta = (iconAlerta, titleAlerta, message, showConfirmButtonAlerta) => {
    const imagen = iconAlerta === 'success' ?
        '<img src="https://www.easy-gst.in/wp-content/uploads/2017/07/success-icon-10.png" alt="Icono personalizado" width="98"'
        : '<img src="https://cdn.icon-icons.com/icons2/317/PNG/512/sign-error-icon_34362.png" width="125">';
    Swal.fire({
        icon: iconAlerta,
        title: titleAlerta,
        confirmButtonColor: '#e33084',
        iconHtml: imagen,
        text: message,
        timer: !showConfirmButtonAlerta ? 2500 : undefined,
        allowOutsideClick: false,
        showConfirmButton: showConfirmButtonAlerta,
    });

}

