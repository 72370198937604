import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import React from 'react'
import api from "../../api/api"
import Swal from 'sweetalert2';
import { useNavigate } from "react-router-dom";
import "./Styles2.css"


export default function DeleteBooking() {


    const navigator = useNavigate()


    useEffect(() => {
        checkToken()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [info, setInfo] = useState({
        local: '',
        local_id: '',
        fecha: '',
        hora: ''
    })
    const [servicios, setServicios] = useState([]);
    const [id, setId] = useState("")

    const { token } = useParams()



    const checkToken = async () => {



        try {

            const body = {
                token: token
            }

            const response = await api.post(`/bookingToken`, body)

            setInfo({
                local: response.data.booking.local.name,
                fecha: response.data.booking.date,
                hora: response.data.booking.hour
            })

            setId(response.data.booking._id)

            setServicios(response.data.services)

        } catch (error) {
            console.error("error", error)
            //navigate("*")
        }
    }


    const deleteBooking = async () => {

        try {
            // Mostrar un cuadro de diálogo de confirmación
            const confirmDelete = await Swal.fire({
                title: '¿Estás seguro?',
                text: 'Esta acción eliminará el elemento. ¿Deseas continuar?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Sí, eliminar',
                cancelButtonText: 'Cancelar'
            });

            // Si el usuario confirma la eliminación
            if (confirmDelete.isConfirmed) {
                Swal.fire({
                    position: 'center',
                    title: 'Espera un momento...',
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    html: '<div style="color: #e33084; overflow-y: hidden;"><i class="fas fa-circle-notch fa-spin fa-3x"></i></div>',

                });

                // Llamar al endpoint de eliminación
                const response = await api.delete(`/booking/${id}`);

                if (response.data.status) {
                    Swal.close();
                    Swal.fire({
                        icon: 'success',
                        confirmButtonColor: '#e33084',
                        iconHtml: '<img src="https://www.easy-gst.in/wp-content/uploads/2017/07/success-icon-10.png" alt="Icono personalizado" width="98">',
                        title: 'Éxito',
                        text: '¡Se ha eliminado el elemento!',
                        allowOutsideClick: false,
                        showConfirmButton: true,
                    }).then((result) => {
                        // Este bloque se ejecuta después de que el usuario confirme la alerta
                        if (result.isConfirmed) {
                            // Puedes realizar acciones adicionales o redirigir si es necesario
                            navigator("/");
                        }
                    });
                }
            }
        } catch (error) {
            // Manejar errores si es necesario
            console.error(error);
        }

    }


    return (
        <div className='container-fluid justify-content-center align-items-center mt-5' style={{ minHeight: '100vh' }}>
            <div className='row justify-content-center align-items-center'>
                <div>
                    <h2>Eliminación de cita</h2>
                    <div className='row justify-content-center mt-3'>
                        <div className='col-sm-5'>
                            <h5>Servicios</h5>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Servicio</th>
                                        <th>Duración</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {servicios.map((servicio, index) => (
                                        <tr key={index}>
                                            <td>{servicio.name}</td>
                                            <td>{servicio.duration} minutos</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className='col-sm-5'>
                            <h5>Local, hora y fecha</h5>
                            <form>
                                <div className="form-group">
                                    <label htmlFor="nombre">Local:</label>
                                    <input
                                        type="text"
                                        id="local"
                                        name="local"
                                        className="form-control"
                                        placeholder="local"
                                        value={info.local}
                                        readOnly // Hace que el campo sea de solo lectura
                                        style={{ backgroundColor: '#f2f2f2' }} // Cambia el fondo a gris claro
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="correo">Fecha:</label>
                                    <input
                                        type="text"
                                        id="fecha"
                                        name="fecha"
                                        className="form-control"
                                        placeholder="fecha"
                                        value={info.fecha}
                                        readOnly // Hace que el campo sea de solo lectura
                                        style={{ backgroundColor: '#f2f2f2' }} // Cambia el fondo a gris claro
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="numero">Hora</label>
                                    <input
                                        type="text"
                                        id="hora"
                                        name="hora"
                                        className="form-control"
                                        placeholder="hora"
                                        value={info.hora}
                                        readOnly // Hace que el campo sea de solo lectura
                                        style={{ backgroundColor: '#f2f2f2' }} // Cambia el fondo a gris claro
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className='row justify-content-center'>
                        <div className='col-sm-5'>
                            <button onClick={deleteBooking} className="btn btn-danger">Eliminar Cita</button>
                        </div>
                    </div>
                </div >
            </div>
        </div>
    )


}
