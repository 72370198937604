import React from 'react'

export default function Header() {
    return (
        <div className='container-fluid' style={{ position: 'fixed', top: 0, left: 0, width: '100%', background: 'white', zIndex: 100 }}>
            <div className='row justify-content-end align-items-left header'>
                <div className='col-sm' style={{ minHeight: '40px' }}>
                </div>
            </div>
        </div>
    )
}
