import React, { useContext, useState } from 'react';
import api from "../../../api/api";
import { Link, useNavigate } from 'react-router-dom';
import "./Styles.css";
import { AuthContext } from '../../../Context/AuthContext';
import { mostrarMensajeAlerta } from '../../../util/alet';

export default function CreateServiceData() {

    const { user } = useContext(AuthContext);


    const navigator = useNavigate();

    const [formData, setFormData] = useState({
        name: '',
        description: '',
        duration: '',
        gender: 'Masculino', // Establecer un valor predeterminado
        type: 'Corporal', // Establecer un valor predeterminado
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (user.permisos?.servicios.editar) {

            try {
                // Convertir la primera letra de gender y type a mayúscula
                const formattedData = {
                    ...formData,
                    gender: formData.gender.charAt(0).toUpperCase() + formData.gender.slice(1),
                    type: formData.type.charAt(0).toUpperCase() + formData.type.slice(1),
                };
                const response = await api.post('/service', formattedData);
                if (response.status === 200) {
                    mostrarMensajeAlerta('success', "Éxito",
                        '¡Servicio creado exitosamente!', false)
                    setTimeout(() => {
                        navigator('/servicios');
                    }, 1500);
                }
            } catch (error) {
                console.error('Error al crear el servicio:', error);
            }

        } else {
            mostrarMensajeAlerta("error", "Error",
                '¡No tienes Permisos!', true);
        }

    };

    return (
            <div className="container-fluid mt-5">
                <div className="row justify-content-center">
                    <div className="col-sm-8">

                        {user.permisos?.servicios.ver ? (
                            <div>
                                <h2>Crear Nuevo Servicio</h2>
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <label>Nombre del Servicio:</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Descripción del Servicio:</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="description"
                                            value={formData.description}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Duración (en minutos):</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="duration"
                                            value={formData.duration}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Género:</label>
                                        <select
                                            className="form-select"
                                            name="gender"
                                            value={formData.gender}
                                            onChange={handleChange}
                                            required
                                        >
                                            <option value="Masculino">Masculino</option>
                                            <option value="Femenino">Femenino</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label>Tipo:</label>
                                        <select
                                            className="form-select"
                                            name="type"
                                            value={formData.type}
                                            onChange={handleChange}
                                            required
                                        >
                                            <option value="Corporal">Corporal</option>
                                            <option value="Facial">Facial</option>
                                            <option value="Bronceado">Bronceado</option>
                                            <option value="Combo">Combo</option>
                                        </select>
                                    </div>
                                    <button type="submit" className="main-button">Crear Servicio</button>  &nbsp; &nbsp;
                                    <Link to="/servicios"><button type="submit" className="main-close">Regresar</button></Link>
                                </form>
                            </div>
                        ) : (<div className='col-sm-5 mt-5'><p>No tienes Permisos</p></div>)}
                    </div>
                </div>
            </div>
    );
}
