import { format, parseISO } from 'date-fns';
import { toZonedTime   } from 'date-fns-tz';
import { es } from 'date-fns/locale';

export function getFechaActual() {
    const fechaActual = new Date();
    const anio = fechaActual.getFullYear();
    const mes = (fechaActual.getMonth() + 1).toString().padStart(2, '0');
    const dia = fechaActual.getDate().toString().padStart(2, '0');
    return `${anio}-${mes}-${dia}`;
}

export function formattedDate(fecha) {
    const isoDate = fecha;
    const timeZone = 'America/Guayaquil';
    const zonedDate = toZonedTime(parseISO(isoDate), timeZone);
    const formattedDate = format(zonedDate, 'yyyy-MM-dd HH:mm:ss', { timeZone });


    return formattedDate;
}

export function convertirFecha(fechaString) {
    const meses = [
        "Ene", "Feb", "Mar", "Abr", "May", "Jun",
        "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"
    ];

    const fecha = new Date(fechaString);
    const dia = fecha.getDate();
    const mes = meses[fecha.getMonth()];
    const anio = fecha.getFullYear();

    return `${anio}-${mes}-${dia}`;
}

export function formatFecha(fechaISO) {
    const timeZone = 'America/Guayaquil';
    const zonedDate = toZonedTime(parseISO(fechaISO), timeZone);
    return format(zonedDate, "dd-MMMM-yyyy", { locale: es });
}