import React, { useState, useEffect, useContext } from 'react';
import api from "../../../api/api";
import Swal from 'sweetalert2';
import { AuthContext } from '../../../Context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { mostrarMensajeAlerta } from '../../../util/alet';
import { formattedDate } from '../../../util/util';
import RefreshIcon from '@mui/icons-material/Refresh';
import ExcelDownloadModal from '../../Components/ExcelDownloadModal';

export default function Booking() {
    const navigator = useNavigate();
    const { user } = useContext(AuthContext);
    const [localesDisponibles, setLocalesDisponibles] = useState([]);
    const [localSeleccionado, setLocalSeleccionado] = useState(null);
    const [booking, setBooking] = useState([]);

    const [fechaSeleccionada, setFechaSeleccionada] = useState('');
    const [intervalId, setIntervalId] = useState(null);

    async function changeState(id, estado) {
        const inputOptions = {
            'Reservado': 'Reservado',
            'Realizada': 'Realizada',
            // 'Cancelada': 'Cancelada',
        };
        delete inputOptions[estado];
        const { value: newState } = await Swal.fire({
            title: 'Selecciona el nuevo estado de la reserva',
            input: 'select',
            inputOptions: inputOptions,
            inputPlaceholder: 'Selecciona un estado',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            inputValidator: (value) => {
                return new Promise((resolve) => {
                    if (value !== '') {
                        resolve();
                    } else {
                        resolve('Debes seleccionar un estado');
                    }
                });
            },
        });
        if (newState) {
            if (newState === 'Cancelada') {
                const { isConfirmed } = await Swal.fire({
                    title: '¿Estás seguro?',
                    text: 'Se mandará un mensaje de cancelación al cliente.',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Sí, cancelar la cita',
                    cancelButtonText: 'No, mantener el estado actual',
                });
                if (!isConfirmed) {
                    return;
                }
            }
            try {
                const response = await api.post(`/booking/state/${id}`, { newState, idUser: user?.id });
                if (response.status === 200) {
                    if (localSeleccionado && fechaSeleccionada) {
                        getCitesForLocalAndDate(localSeleccionado, fechaSeleccionada);
                    }
                    mostrarMensajeAlerta("success", "Reserva actualizada con éxito", "", false);
                }
            } catch (error) {
                mostrarMensajeAlerta("error", "Error al actualizar la reserva", "Ha ocurrido un error al actualizar la reserva", false);
            }
        }
    }
    /*
        async function eliminateBooking(id) {
            if (user.permisos?.citas.eliminar) {
                const result = await Swal.fire({
                    title: '¿Estás seguro de eliminar esta reserva?',
                    text: 'Esta acción no se puede deshacer.',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Sí, eliminar',
                    cancelButtonText: 'Cancelar',
                });
    
                if (result.isConfirmed) {
                    try {
                        const response = await api.delete(`/booking/${id}`);
                        if (response.status === 200) {
                            if (localSeleccionado && fechaSeleccionada) {
                                getCitesForLocalAndDate(localSeleccionado, fechaSeleccionada)
                            }
                            getCitesForLocalAndDate(localSeleccionado, fechaSeleccionada)
                            mostrarMensajeAlerta("success", "Reserva eliminada exitosamente",
                                '', false);
                        }
                    } catch (error) {
                        mostrarMensajeAlerta("error", "Error al eliminar la reserva",
                            'Ha ocurrido un error al eliminar la reserva.', true);
                    }
                }
            } else {
                mostrarMensajeAlerta("error", "!No tienes permisos",
                    '!No tienes permisos', true);
            }
        }
    */

    async function cancelBooking(id) {
        const { isConfirmed } = await Swal.fire({
            title: 'CANCELAR CITA',
            text: '¿Estás seguro? Al presionar sí, se mandará un mensaje de cancelación de la cita al cliente.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sí, cancelar la cita',
            cancelButtonText: 'No, no cancelar citar'
        });
        if (!isConfirmed) {
            return;
        }
        try {
            const response = await api.post(`/booking/state/${id}`, { newState: 'Cancelada', idUser: user?.id });
            if (response.status === 200) {
                if (localSeleccionado && fechaSeleccionada) {
                    getCitesForLocalAndDate(localSeleccionado, fechaSeleccionada);
                }
                mostrarMensajeAlerta("success", "Reserva actualizada con éxito", "", false);
            }
        } catch (error) {
            mostrarMensajeAlerta("error", "Error al actualizar la reserva", "Ha ocurrido un error al actualizar la reserva", false);
        }
    }

    function getFechaActual() {
        const fechaActual = new Date();
        const anio = fechaActual.getFullYear();
        const mes = (fechaActual.getMonth() + 1).toString().padStart(2, '0');
        const dia = fechaActual.getDate().toString().padStart(2, '0');
        return `${anio}-${mes}-${dia}`;
    }

    function compareHours(a, b) {
        const timeA = new Date(`01/01/2022 ${a.hour}`);
        const timeB = new Date(`01/01/2022 ${b.hour}`);
        return timeA - timeB;
    }

    async function getCitesForLocalAndDate(selectedLocal, fecha) {
        const body = {
            date: fecha
        };
        try {
            const response = await api.post(`/booking/${selectedLocal._id}`, body);
            if (response.status === 200) {
                const sortedBooking = [...response.data.booking].sort(compareHours);
                setBooking(sortedBooking);
            }
        } catch (error) {
            console.error('Error al obtener la lista de locales:', error);
        }
    }

    useEffect(() => {
        async function fetchLocales() {
            try {
                const response = await api.get('/local');
                if (response.status === 200) {
                    if (response.data.locals) {
                        setLocalSeleccionado(response.data.locals[0]);

                    }
                    setFechaSeleccionada(getFechaActual());
                    setLocalesDisponibles(response.data.locals);
                }
            } catch (error) {
                console.error('Error al obtener la lista de locales:', error);
            }
        }
        fetchLocales();
    }, []);

    useEffect(() => {
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [intervalId]);

    useEffect(() => {
        if (localSeleccionado && fechaSeleccionada) {
            getCitesForLocalAndDate(localSeleccionado, fechaSeleccionada);

            const id = setInterval(() => {
                getCitesForLocalAndDate(localSeleccionado, fechaSeleccionada);
            }, 60000); // Actualiza cada 1 minuto

            setIntervalId(id);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localSeleccionado, fechaSeleccionada]);

    const refreshBooking = () => {
        if (localSeleccionado && fechaSeleccionada) {
            getCitesForLocalAndDate(localSeleccionado, fechaSeleccionada);
        }
    }
    return (
        <div className="container">
            <div className="row justify-content-center align-items-center">
                <div className="col-sm mt-5">
                    {user.permisos?.citas.ver ? (
                        <div>
                            <label>Selecciona un Local:</label>
                            <select
                                className="form-select"
                                value={localSeleccionado ? localSeleccionado.local : ''}
                                onChange={(e) => {
                                    const selectedLocal = localesDisponibles.find(local => local.local === e.target.value);
                                    setLocalSeleccionado(selectedLocal);
                                    if (selectedLocal) {
                                        if (fechaSeleccionada) {
                                            getCitesForLocalAndDate(selectedLocal, fechaSeleccionada);
                                        }
                                    } else {
                                        setBooking([]);
                                    }
                                }}
                            >
                                <option value="">Seleccionar local</option>
                                {localesDisponibles.map((local, index) => (
                                    <option key={index} value={local.local}>
                                        {local.local}
                                    </option>
                                ))}
                            </select>

                            <label>Selecciona una Fecha:</label>
                            <div className="d-flex">
                                <input
                                    type="date"
                                    value={fechaSeleccionada}
                                    style={{ width: "auto" }}
                                    onChange={(e) => {
                                        const nuevaFecha = e.target.value;
                                        setFechaSeleccionada(nuevaFecha);
                                        if (localSeleccionado) {
                                            if (nuevaFecha) {
                                                getCitesForLocalAndDate(localSeleccionado, nuevaFecha);
                                            }
                                        }
                                    }}
                                    className="form-control"
                                />
                                <button type="button" className="btn " onClick={refreshBooking} >
                                    <RefreshIcon />
                                </button>
                                <ExcelDownloadModal idLocal={localSeleccionado?._id} />
                            </div>
                            {localSeleccionado && fechaSeleccionada ? (
                                <div className="table-responsive mt-1">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Servicios</th>
                                                <th>Empleado</th>
                                                <th>Duración</th>
                                                <th>Hora</th>
                                                <th>Cliente</th>
                                                <th>Correo</th>
                                                <th>Número de telefono</th>
                                                <th>Referencia del cliente</th>
                                                <th>Fecha Agendada</th>
                                                <th>Estado</th>
                                                <th>Cambiar Estado</th>
                                                {user && user.permisos.citas.editar && (<th>Editar</th>)}
                                                {user && user.permisos.citas.eliminar && (<th>Eliminar / Cancelar cita</th>)}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {booking.map((agenda, index) => (
                                                <tr key={index}>
                                                    <td>{agenda.isWorkoutTeensChecked && "Workout Teens, "}{agenda.services.join(", ")}</td>
                                                    <td>{agenda.employe ? agenda.employe.indentification : "N/A"}</td>
                                                    <td>{agenda.duration}</td>
                                                    <td>{agenda.hour}</td>
                                                    <td>{agenda.client.name}{" "}{agenda.client.lastName}</td>
                                                    <td>{agenda.client.email?.toLowerCase()}</td>
                                                    <td>{agenda.client.number}</td>
                                                    <td>{agenda.origin ? agenda.origin : 'N/A'}</td>
                                                    <td>{agenda.createdAt ? formattedDate(agenda.createdAt) : "N/A"}</td>
                                                    <td>{agenda.state}</td>
                                                    <td><button className='btn btn-primary' onClick={() => changeState(agenda._id, agenda.state)}>Cambiar</button></td>
                                                    {user && user.permisos.citas.editar && (<td>
                                                        <button className='btn btn-success' onClick={() => {
                                                            if (user.permisos?.citas.editar) {
                                                                navigator(`/modify/${agenda.token}`)
                                                            } else {
                                                                mostrarMensajeAlerta("error", "!No tienes permisos",
                                                                    '!No tienes permisos', true);
                                                            }
                                                        }}>Editar</button>
                                                    </td>)}
                                                    {user && user.permisos.citas.eliminar && (
                                                        <td><button onClick={() =>
                                                            // eliminateBooking(agenda._id)
                                                            cancelBooking(agenda._id)
                                                        } className='btn btn-danger'>Eliminar</button></td>
                                                    )}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>) : (
                                <p>Selecciona un local para ver los horarios.</p>
                            )}
                        </div>
                    ) : (<div className='col-sm-5 mt-5'><p>No tienes Permisos</p></div>)}

                </div>
            </div>
        </div >
    );
}
